import { AdjustmentType, FocusConstraintType, AspectRatiosType } from "./camera.types";

// Note: limit is in ms
export const slowVideoLimit = 30000;
export const fastVideoLimit = 15000;

export const slowVideoDiff = 2500;
export const fastVideoDiff = 1000;

export const DEFAULT_CAPTURE_FRAME_RATE = 30;

export const videoResolutionOptions = [
  { label: "1200x1200px", value: "1200" },
  { label: "1600x1200px", value: "1600" },
  { label: "1920x1080px", value: "1920" },
  { label: "1536x1536px", value: "1536" },
  { label: "2048x1536px", value: "2048" },
  { label: "1944x1944px", value: "1944" },
  { label: "2160x2160px", value: "2160" },
];

export const photoResolutionOptions = [
  { label: "1200x1200px", value: "1200" },
  { label: "1600x1200px", value: "1600" },
  { label: "1920x1080px", value: "1920" },
  { label: "1536x1536px", value: "1536" },
  { label: "2048x1536px", value: "2048" },
  { label: "1944x1944px", value: "1944" },
  { label: "2592x1944px", value: "2592" },
  { label: "2160x2160px", value: "2160" },
  { label: "3840x2160px", value: "3840" },
];

export const videoResolutionValues = {
  "1200": [1200, 1200],
  "1600": [1600, 1200],
  "1920": [1920, 1080],
  "1536": [1536, 1536],
  "2048": [2048, 1536],
  "1944": [1944, 1944],
  "2160": [2160, 2160],
} as const;

export const photoResolutionValues = {
  "2592": [2592, 1944],
  "3840": [3840, 2160],
  "1728x2160": [1728, 2160],
  "1080x1350": [1080, 1350],
  "1080x1920": [1080, 1920],
  "1215x2160": [1215, 2160],
  "2160x1131": [2160, 1131],
  "2292x1200": [2292, 1200],
  "3840x2010": [3840, 2010],
  ...videoResolutionValues,
} as const;

export const facebookDimensionsOptions = [
  { label: "1080x1350px", value: "1080x1350" },
  { label: "1200x1200px", value: "1200x1200" },
  { label: "1080x1920px", value: "1080x1920" },
];
export const instagramDimensionsOptions = [
  { label: "1080x1350px", value: "1080x1350" },
  { label: "1080x1920px", value: "1080x1920" },
  { label: "1080x1080px", value: "1080x1080" },
  { label: "1080x608px", value: "1080x608" },
];
export const youtubeDimensionsOptions = [
  { label: "1280x720px", value: "1280x720" },
  { label: "1920x1080px", value: "1920x1080" },
];
export const linkedInDimensionOptions = [
  { label: "1080x1080px", value: "1080x1080" },
  { label: "1920x1080px", value: "1920x1080" },
  { label: "1200x644px", value: "1200x644" },
  { label: "1200x627px", value: "1200x627" },
  { label: "1080x1920px", value: "1080x1920" },
];
export const tikTokDimensionOptions = [{ label: "1,080 x 1,920 px", value: "1080x1920" }];
export const pinterestDimensionsOptions = [
  { label: "1000x1500px", value: "1000x1500" },
  { label: "1000x1000px", value: "1000x1000" },
  { label: "1000x2100px", value: "1000x2100" },
  { label: "1080x1920px", value: "1080x1920" },
  { label: "3840x2160px", value: "3840x2160" },
];
export const behanceDimensionsOptions = [
  { label: "1600x1200px", value: "1600x1200" },
  { label: "1920x1080px", value: "1920x1080" },
  { label: "2000x2000px", value: "2000x2000" },
  { label: "3840x2160px", value: "3840x2160" },
];
export const shopifyDimensionsOptions = [
  { label: "3840x2160px", value: "3840x2160" },
  { label: "2048x2048px", value: "2048x2048" },
  { label: "1024x1024px", value: "1024x1024" },
  { label: "1600x500px", value: "1600x500" },
  { label: "1920x1080px", value: "1920x1080" },
  { label: "1800x1000px", value: "1800x1000" },
  { label: "1600x900px", value: "1600x900" },
  { label: "800x800px", value: "800x800" },
];
export const amazonDimensionsOptions = [
  { label: "1000x1000px", value: "1000x1000" },
  { label: "2000x2000px", value: "2000x2000" },
  { label: "1920x1080px", value: "1920x1080" },
  { label: "3840x2160px", value: "3840x2160" },
];
export const etsyDimensionsOptions = [
  { label: "1600x400px", value: "1600x400" },
  { label: "1200x200px", value: "1200x200" },
  { label: "2000x2000px", value: "2000x2000" },
  { label: "3840x2160px", value: "3840x2160" },
];
export const normalDimensionsOptions = [
  { label: "500x500px", value: "500x500" },
  { label: "800×800px", value: "800×800" },
  { label: "1000×1000px", value: "1000×1000" },
  { label: "1500×1500px", value: "1500×1500" },
  { label: "2000×2000px", value: "2000×2000" },
];

export const combinedDimensionOptions = [
  ...facebookDimensionsOptions,
  ...instagramDimensionsOptions,
  ...youtubeDimensionsOptions,
  ...linkedInDimensionOptions,
  ...tikTokDimensionOptions,
  ...pinterestDimensionsOptions,
  ...behanceDimensionsOptions,
  ...shopifyDimensionsOptions,
  ...amazonDimensionsOptions,
  ...etsyDimensionsOptions,
];

export const marketplaceDimensionsValues = {
  "1080x1350": [1080, 1350],
  "1200x1200": [1200, 1200],
  "1080x1920": [1080, 1920],
  "1080x1080": [1080, 1080],
  "1080x608": [1080, 608],
  "1280x720": [1280, 720],
  "1920x1080": [1920, 1080],
  "1200x644": [1200, 644],
  "1200x627": [1200, 627],
  "1000x1500": [1000, 1500],
  "1000x1000": [1000, 1000],
  "1000x2100": [1000, 2100],
  "3840x2160": [3840, 2160],
  "1600x1200": [1600, 1200],
  "2000x2000": [2000, 2000],
  "2048x2048": [2048, 2048],
  "1024x1024": [1024, 1024],
  "1600x500": [1600, 500],
  "1800x1000": [1800, 1000],
  "1600x900": [1600, 900],
  "800x800": [800, 800],
  "1600x400": [1600, 400],
  "1200x200": [1200, 200],
  "500x500": [500, 500],
  "800×800": [800, 800],
  "1000×1000": [1000, 1000],
  "1500×1500": [1500, 1500],
  "2000×2000": [2000, 2000],
} as const;

export const focusConstraint: FocusConstraintType = {
  focusMode: "continuous",
  focusDistance: {
    max: 255,
    min: 0,
    step: 1,
  },
};

export const adjustments: AdjustmentType[] = [
  {
    name: "brightness",
    uniform: "u_brightness",
    min: -1,
    max: 1,
    step: 0.05,
    hidden: true,
  },
  {
    name: "brightness",
    uniform: "u_levelsBrightness",
    min: -0.6,
    max: 0.6,
    step: 0.01,
  },
  {
    name: "levelsTone",
    uniform: "u_levelsTone",
    min: -0.3,
    max: 0.3,
    step: 0.01,
  },
  {
    name: "saturation",
    uniform: "u_saturation",
    min: -1.5,
    max: 1.5,
    step: 0.05,
  },
  {
    name: "sharpness",
    uniform: "u_sharpness",
    min: -1,
    max: 1,
    step: 0.05,
  },
  {
    name: "contrast",
    uniform: "u_contrast",
    min: -1.5,
    max: 1.5,
    step: 0.05,
  },
  {
    name: "hue",
    uniform: "u_hue",
    min: 0,
    max: 360,
    step: 1,
    hidden: true,
  },
];

export const cameraFiltersLocalStorageEntity = "camera-filters";
export const cameraSettingsLocalStorageEntity = "camera-settings-filterValue-";
export const cameraPresetLocalStorageEntity = "camera-preset";
export const cameraFilterModeLocalStorageEntity = "camera-filter-mode";
export const cameraScenarioLocalStorageEntity = "camera-scenario";
// Note: If possible, it should be managed uniformly in the future.
export const cameraDpiLocalStorageEntity = "camera-dpi";
export const cameraLogoWatermarkModeLocalStorageEntity = "camera-logowatermarkmode";
export const cameraLocalSaveSettingsStorageEntity = "camera-local-save-settings";
export const cameraCaptureModeStorageEntity = "camera-capture-mode";

export const turnTableBluetoothServiceUUID = "0000ffe0-0000-1000-8000-00805f9b34fb";
export const turnTableBluetoothCharacteristicUUID = "0000ffe1-0000-1000-8000-00805f9b34fb";

export const turnTableDegreesControl = {
  360: "360",
  180: "180",
  90: "090",
  45: "045",
  30: "030",
  15: "015",
};

export const turnTableSpeedControl = {
  0: "9", // fast
  1: "8", // slow
};

export const turnTableDirectionControl = {
  right: "L",
  left: "R",
};

export const storageSpaceUnit = {
  MB: 2,
  GB: 3,
};

export const defaultMiniStorageSpace = {
  miniStorageSpace: 100,
  Unit: "MB",
};

export const aspectRatiosOptions = {
  photo: [
    {
      name: AspectRatiosType.One2One,
      value: [
        { label: "1200x1200px", value: "1200" },
        { label: "1536x1536px", value: "1536" },
        { label: "1944x1944px", value: "1944" },
        { label: "2160x2160px", value: "2160" },
      ],
    },
    {
      name: AspectRatiosType.Four2Three,
      value: [
        { label: "1600x1200px", value: "1600" },
        { label: "2048x1536px", value: "2048" },
        { label: "2592x1944px", value: "2592" },
      ],
    },
    {
      name: AspectRatiosType.Sixteen2Nine,
      value: [
        { label: "1920x1080px", value: "1920" },
        { label: "3840x2160px", value: "3840" },
      ],
    },
    {
      name: AspectRatiosType.Four2Five,
      value: [
        { label: "1080x1350px", value: "1080x1350" },
        { label: "1728x2160px", value: "1728x2160" },
      ],
    },
    {
      name: AspectRatiosType.Nine2Sixteen,
      value: [
        { label: "1080x1920px", value: "1080x1920" },
        { label: "1215x2160px", value: "1215x2160" },
      ],
    },
    {
      name: AspectRatiosType.OnePointNineOne2One,
      value: [
        { label: "2292x1200px", value: "2292x1200" },
        { label: "3840x2010px", value: "3840x2010" },
      ],
    },
  ],
  video: [
    {
      name: AspectRatiosType.One2One,
      value: [
        { label: "1200x1200px", value: "1200" },
        { label: "1536x1536px", value: "1536" },
        { label: "1944x1944px", value: "1944" },
        { label: "2160x2160px", value: "2160" },
      ],
    },
    {
      name: AspectRatiosType.Four2Three,
      value: [
        { label: "1600x1200px", value: "1600" },
        { label: "2048x1536px", value: "2048" },
      ],
    },
    {
      name: AspectRatiosType.Sixteen2Nine,
      value: [{ label: "1920x1080px", value: "1920" }],
    },
    {
      name: AspectRatiosType.Four2Five,
      value: [
        { label: "1080x1350px", value: "1080x1350" },
        { label: "1728x2160px", value: "1728x2160" },
      ],
    },
    {
      name: AspectRatiosType.Nine2Sixteen,
      value: [
        { label: "1080x1920px", value: "1080x1920" },
        { label: "1215x2160px", value: "1215x2160" },
      ],
    },
    {
      name: AspectRatiosType.OnePointNineOne2One,
      value: [{ label: "2160x1131px", value: "2160x1131" }],
    },
  ],
};
